import { Link } from "react-router-dom";
import { To } from "history";

// material-ui
import { ButtonBase } from "@mui/material";
import { SxProps } from "@mui/system";

// project import
// import Logo from './LogoMain';
// import LogoIcon from './LogoIcon';
import IOMarket from "assets/images/icons/logo-icon-io.svg";
import IOMarketLogo from "assets/images/icons/logo.svg";

import { APP_DEFAULT_PATH } from "config";
// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={!to ? APP_DEFAULT_PATH : to}
      sx={sx}
    >
      {isIcon ? (
        <img src={IOMarket} style={{ width: "85%" }} />
      ) : (
        <img
          src={IOMarketLogo}
          style={{ width: "60%", marginRight: " 90px" }}
        />
      )}
    </ButtonBase>
  );
};

export default LogoSection;
