// material-ui
import { Theme } from "@mui/material/styles";
import { useMediaQuery, Box } from "@mui/material";

// project import
import Profile from "./Profile";
import MobileSection from "./MobileSection";

import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";

// types
import { MenuOrientation } from "types/config";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      <Box sx={{ textAlign: "right", width: "100%" }}>
        {!downLG && <Profile />}
        {downLG && <MobileSection />}
      </Box>
    </>
  );
};

export default HeaderContent;
