// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  UploadOutlined,
  FileOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  UploadOutlined,
  FileOutlined,
  DatabaseOutlined,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other: NavItemType = {
  id: "other",
  type: "group",
  children: [
    {
      id: "queue",
      title: <FormattedMessage id="queue" />,
      type: "item",
      url: "/queue",
      icon: icons.FileOutlined,
    },
    {
      id: "upload",
      title: <FormattedMessage id="upload" />,
      type: "item",
      url: "/upload",
      icon: icons.UploadOutlined,
    },
    {
      id: "Logs",
      title: <FormattedMessage id="logs" />,
      type: "item",
      url: "/logs",
      icon: icons.DatabaseOutlined,
    },
  ],
};

export default other;
