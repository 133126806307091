import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { Navigate } from "react-router-dom";
// pages routing

// render Pages
const Queue = Loadable(lazy(() => import("pages/extra-pages/queue")));
const Upload = Loadable(lazy(() => import("pages/extra-pages/upload")));
const Edit = Loadable(lazy(() => import("pages/extra-pages/edit")));
const Logs = Loadable(lazy(() => import("pages/extra-pages/logging")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "queue",
          element: <Queue />,
        },
        {
          path: "upload",
          element: <Upload />,
        },
        {
          path: "edit/:id",
          element: <Edit />,
        },
        {
          path: "logs",
          element: <Logs />,
        },
        {
          path: "*",
          element: <Navigate to="/queue" replace />,
        },
      ],
    },
  ],
};

export default MainRoutes;
